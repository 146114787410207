import { Model } from "@tailflow/laravel-orion/lib/model";

type BrandAttributes = {
    id: number;
    name: string;
};
export class BrandApi extends Model<BrandAttributes> {
    public $resource(): string {
        return "brands";
    }
}
