import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36cade03"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "copyright" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_item_group = _resolveComponent("ion-item-group")!
  const _component_LocaleModal = _resolveComponent("LocaleModal")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_openBlock(), _createBlock(_component_ion_menu, {
    "content-id": "main",
    type: "overlay",
    "menu-id": "main-content"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { class: "sidebar-content" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, { id: "pages-list" }, {
            default: _withCtx(() => [
              (_ctx.user)
                ? (_openBlock(), _createBlock(_component_ion_avatar, {
                    key: 0,
                    slot: "start",
                    class: "user-avatar"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        src: `https://ui-avatars.com/api/?name=${_ctx.user.ambassador.name}&color=aaa9a5&background=766b58`
                      }, null, 8, _hoisted_1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.user)
                ? (_openBlock(), _createBlock(_component_ion_list_header, { key: 1 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.user.ambassador.name), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_note, { class: "user-email" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.user.email), 1)
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appPages, (p, i) => {
                return (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                  "auto-hide": "false",
                  key: i
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, {
                      onClick: ($event: any) => (p.action(i)),
                      "router-direction": "root",
                      "router-link": p.url,
                      lines: "none",
                      detail: "false",
                      class: _normalizeClass(["hydrated menu-item", { selected: _ctx.selectedIndex === i, [p.class]: true }])
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          slot: "start",
                          ios: p.iosIcon,
                          md: p.mdIcon
                        }, null, 8, ["ios", "md"]),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(p.title), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick", "router-link", "class"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_ion_list, { id: "labels-list" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item_group, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item_divider, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_list_header, { class: "subtitles" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.manage')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labels, (label, index) => {
                    return (_openBlock(), _createBlock(_component_ion_item_divider, {
                      class: "menu-action",
                      lines: "none",
                      key: index,
                      onClick: label.action,
                      style: {"padding":"0rem 1rem"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          slot: "start",
                          ios: label.icon,
                          md: label.icon
                        }, null, 8, ["ios", "md"]),
                        _createVNode(_component_ion_label, { class: "manage-label" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(label.name), 1)
                          ]),
                          _: 2
                        }, 1024),
                        (label.showLocaleIcon)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              src: _ctx.currentLocaleImgSrc,
                              class: "lang-menu-icon"
                            }, null, 8, _hoisted_2))
                          : _createCommentVNode("", true),
                        _createVNode(_component_ion_icon, {
                          slot: "end",
                          ios: _ctx.chevronForwardOutline,
                          md: _ctx.chevronForwardOutline,
                          size: "small"
                        }, null, 8, ["ios", "md"])
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_LocaleModal, { ref: "localeModalRef" }, null, 512),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.copyright')), 1)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}