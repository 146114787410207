import { HasMany } from "@tailflow/laravel-orion/lib/drivers/default/relations/hasMany";
import { Model } from "@tailflow/laravel-orion/lib/model";
import { BrandApi } from "./BrandApi";

type CategoryAttributes = {
    name: string;
    id: number;
};
export class CategoryApi extends Model<CategoryAttributes> {
    public $resource(): string {
        return "categories";
    }
    public brands(): HasMany<BrandApi> {
        return new HasMany(BrandApi, this);
    }
}
