import { Shift } from "@/classes/Shift";
import { shiftMapper, shiftsMapper, simpleShiftMapper } from "./mappers/Shift";
import { getAmbassador } from "./ambassador";
import { SortDirection } from "@tailflow/laravel-orion/lib/drivers/default/enums/sortDirection";
import {
    changeTimeToDbFormat,
    currentDateForDB,
    getPreviousWeek,
    yesterdayDateForDB,
} from "@/utils/DateManager";
import { ShiftAttributes } from "./models/ShiftApi";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";

export const FINISHED_SHIFT_STATUS = "published";
export const DRAFT_SHIFT_STATUS = "draft";

const getShiftsFromAmbassador = (ambassadorId: number) => {
    if (!ambassadorId) return Promise.reject( new Error("ambassadorId is missing") );
    return getAmbassador(ambassadorId).then((ambassador) =>
        ambassador.shifts()
    );
}

export const getLastTenShifts = (ambassadorId: number) =>
    getShiftsFromAmbassador(ambassadorId).then((shifts) => {
        return shifts
            .with(["location", "sales.products"])
            .sortBy("starts_at", SortDirection.Desc)
            .search(10)
            .then(shiftsMapper);
    });

export const startShift = (
    ambassadorId: number,
    locationId: number,
    startsAt: string,
    endsAt: string
) => {
    return getShiftsFromAmbassador(ambassadorId).then((shifts) => {
        return (
            shifts
                //@ts-ignore
                .store({
                    location_id: locationId,
                    starts_at: startsAt,
                    ends_at: endsAt,
                })
                .then(simpleShiftMapper)
        );
    });
};

export const getLastShift = (ambassadorId: number): Promise<Shift> => {
    return getShiftsFromAmbassador(ambassadorId).then((shifts) => {
        return shifts
            .sortBy("starts_at", SortDirection.Desc)
            .with(["location", "sales.products"])
            .search()
            .then((shifts) => {
                if (shifts.length === 0) throw new Error("User has no shifts");
                const currentShift = shifts[0];
                if (!currentShift) throw new Error("User has no shift active");
                return shiftMapper(shifts[0]);
            });
    });
};

export const getYesterdayShift = (ambassadorId: number): Promise<Shift> => {
    return getShiftsFromAmbassador(ambassadorId).then((shifts) => {
        return shifts
            .sortBy("starts_at", SortDirection.Desc)
            .with(["location", "sales.products"])
            .filter(
                "starts_at",
                FilterOperator.LessThanOrEqual,
                getPreviousWeek(yesterdayDateForDB())
            )
            .search()
            .then((shifts) => {
                const currentShift = shifts[0];
                if (!currentShift) throw new Error("User has no shift active");
                return shiftMapper(shifts[0]);
            });
    });
};

export const getLastWeekShift = (ambassadorId: number): Promise<Shift> => {
    return getShiftsFromAmbassador(ambassadorId).then((shifts) => {
        return shifts
            .sortBy("starts_at", SortDirection.Desc)
            .with(["location", "sales.products"])
            .filter(
                "starts_at",
                FilterOperator.GreaterThanOrEqual,
                getPreviousWeek(currentDateForDB())
            )
            .search()
            .then((shifts) => {
                const currentShift = shifts[0];
                if (!currentShift) throw new Error("User has no shift active");
                return shiftMapper(shifts[0]);
            });
    });
};

export const getShiftById = (
    id: number,
    ambassadorId: number
): Promise<Shift> => {
    return getShiftsFromAmbassador(ambassadorId).then((shifts) => {
        return shifts
            .with(["location", "sales.products"])
            .find(id)
            .then((shift) => {
                return shiftMapper(shift);
            });
    });
};

export const getShiftsFromRange = (
    startDate: string,
    endDate: string,
    ambassadorId: number,
    withDraftShifts = false
): Promise<Shift[]> => {
    return getShiftsFromAmbassador(ambassadorId).then((shifts) => {
        return shifts
            .with(["location", "sales.products"])
            .filter(
                "starts_at",
                FilterOperator.LessThanOrEqual,
                changeTimeToDbFormat(endDate)
            )
            .filter(
                "starts_at",
                FilterOperator.GreaterThanOrEqual,
                changeTimeToDbFormat(startDate)
            )
            .filter(
                "status",
                FilterOperator.In,
                withDraftShifts
                    ? [FINISHED_SHIFT_STATUS, DRAFT_SHIFT_STATUS]
                    : [FINISHED_SHIFT_STATUS]
            )
            .sortBy("starts_at", SortDirection.Desc)
            .search()
            .then(shiftsMapper);
    });
};

export const stopShift = (
    ambassadorId: number,
    shiftId: number,
    reason: string
) =>
    getShiftsFromAmbassador(ambassadorId)
        .then((shifts) => {
            return shifts.find(shiftId);
        })
        .then((selectedShift) => {
            selectedShift.$attributes.ends_at = currentDateForDB();
            selectedShift.$attributes.notes = reason;
            return selectedShift.$save();
        });

export const editShift = (
    ambassadorId: number,
    shiftId: number,
    attributesChanged: Partial<ShiftAttributes>
) =>
    getShiftsFromAmbassador(ambassadorId)
        .then((shifts) => {
            return shifts.find(shiftId);
        })
        .then((selectedShift) => {
            selectedShift.$attributes = {
                ...selectedShift.$attributes,
                ...attributesChanged,
            };
            return selectedShift.$save();
        });
