import { Model } from "@tailflow/laravel-orion/lib/model";
import { BelongsTo } from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo";
import { BrandApi } from "./BrandApi";

type BrandLineAttributes = {
    name: string;
    id: number;
};
export class BrandLineApi extends Model<
    BrandLineAttributes,
    BrandLineAttributes,
    {
        brand: BrandApi;
    }
> {
    public $resource(): string {
        return "brand-line";
    }
    public brand(): BelongsTo<BrandApi> {
        return new BelongsTo(BrandApi, this);
    }
}
