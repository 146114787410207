import { Shift } from "@/classes/Shift";
import {
    shiftHasBeenPublished,
    shiftIsOnActiveMode,
    shiftIsOnEditMode,
} from "@/utils/ShiftManager";
import { Module } from "vuex";

export const shiftModule: Module<any, any> = {
    state: () => ({
        shift: {},
        lastShift: {},
    }),
    getters: {
        lastShift(state) {
            return state.lastShift;
        },
        shiftId(state) {
            return state.shift.id;
        },
        shiftDate(state) {
            return state.shift.date;
        },
        currentShift(state) {
            return state.shift;
        },
        shiftIsCurrent(state) {
            return shiftIsOnActiveMode(state.shift);
        },
        shiftIsEditable(state) {
            return shiftIsOnEditMode(state.shift);
        },
        shiftHasBeenPublished(state) {
            return shiftHasBeenPublished(state.shift);
        },
    },
    mutations: {
        setShift(state, shift: Shift) {
            return (state.shift = { ...shift });
        },
        setLastShift(state, shift: Shift) {
            return (state.lastShift = { ...shift });
        },
    },
    actions: {
        setShift({ commit }, shift: Shift) {
            return commit("setShift", shift);
        },
        setLastShift({ commit }, shift: Shift) {
            return commit("setLastShift", shift);
        },
    },
};
