import { Shift } from "@/classes/Shift";
import { ShiftApi } from "../models/ShiftApi";
import { formatOrders } from "./OrderMapper";

export const simpleShiftMapper = (shift: ShiftApi): Shift => ({
    id: shift.$attributes.id,
    startTime: shift.$attributes.starts_at,
    status: shift.$attributes.status,
    endTime: shift.$attributes.ends_at,
    location: shift.$attributes.location?.name,
});

export const shiftMapper = (shift: ShiftApi): Shift => ({
    id: shift.$attributes.id,
    startTime: shift.$attributes.starts_at,
    status: shift.$attributes.status,
    endTime: shift.$attributes.ends_at,
    locationId: shift.$attributes.location_id,
    location: shift.$attributes.location?.name,
    sales: shift.$relations.sales ? formatOrders(shift.$relations.sales) : [],
});

export const shiftsMapper = (shifts: ShiftApi[]): Shift[] =>
    shifts.map(shiftMapper);
