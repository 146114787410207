const LOCAL_STORAGE_LOCALE_KEY = "bacardi:locale";

export enum SUPPORTED_LANG {
    EN = "en",
    ES = "es",
    ZH = "zh",
}

export interface SUPPORTED_LANG_EXTENDED {
  value: SUPPORTED_LANG,
  shortName: string,
  name:string,
  imgPath: string
}

export const DEFAULT_LANG: SUPPORTED_LANG_EXTENDED = {
    value: SUPPORTED_LANG.EN,
    shortName: "en",
    name: "English",
    imgPath:
        "https://bacardi-sales-tracker-files.s3.eu-west-1.amazonaws.com/en.png",
};

export const SUPPORTED_LANGS : SUPPORTED_LANG_EXTENDED[] = [
    DEFAULT_LANG,
    {
        value: SUPPORTED_LANG.ES,
        shortName: "es",
        name: "Español",
        imgPath:
            "https://bacardi-sales-tracker-files.s3.eu-west-1.amazonaws.com/sp.png",
    },
    {
        value: SUPPORTED_LANG.ZH,
        shortName: "zh",
        name: "Chinese",
        imgPath:
            "https://bacardi-sales-tracker-files.s3.eu-west-1.amazonaws.com/zh.png",
    },
];

export const setUserLocaleSetting = (lang: SUPPORTED_LANG) => {
    return localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, lang);
};

const getBrowserLang = (): string => {
    if (navigator.languages != undefined) return navigator.languages[0];
    return navigator.language;
};

const getUserLocaleSetting = () => {
    return localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY);
};

const getLangForI18n = (lang: string) => {
    if (/^en\b/.test(lang)) {
        return SUPPORTED_LANG.EN;
    }
    if (/^es\b/.test(lang)) {
        return SUPPORTED_LANG.ES;
    }
};

export const getUserLocale = () => {
    const userLocale = getUserLocaleSetting();
    if (userLocale) return userLocale;
    const browserLang = getBrowserLang();
    return getLangForI18n(browserLang);
};
