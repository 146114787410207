import { Model } from "@tailflow/laravel-orion/lib/model";
import { ProductApi } from "./ProductApi";
import { HasMany } from "@tailflow/laravel-orion/lib/drivers/default/relations/hasMany";

export type SaleAttributes = {
    id: number;
    notes: string;
    created_at: string;
    shift_id: number;
    products: ProductLineApi[];
};

export interface ProductLineApi extends ProductApi {
    pivot: {
        sale_id: number;
        product_id: number;
        quantity: number;
    };
}

export class SaleApi extends Model<
    SaleAttributes,
    SaleAttributes,
    {
        products: ProductLineApi[];
    }
> {
    public $resource(): string {
        return "sales";
    }
    public products(): HasMany<ProductApi> {
        return new HasMany(ProductApi, this);
    }
}
