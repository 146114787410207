import { Model } from "@tailflow/laravel-orion/lib/model";
import { SaleApi } from "./SaleApi";
import { HasMany } from "@tailflow/laravel-orion/lib/drivers/default/relations/hasMany";

export type ShiftAttributes = {
    id: number;
    starts_at: string;
    ends_at: string;
    notes: string;
    status: string;
    ambassador_id: number;
    location_id: number;
    location: { id: number; name: string };
};

export class ShiftApi extends Model<
    ShiftAttributes,
    ShiftAttributes,
    { sales: SaleApi[] }
> {
    public $resource(): string {
        return "shifts";
    }
    public sales(): HasMany<SaleApi> {
        return new HasMany(SaleApi, this);
    }
}
