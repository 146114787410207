import { Model } from "@tailflow/laravel-orion/lib/model";
import { AmbassadorApi } from "./AmbassadorApi";

type UserAttributes = {
    id: number;
    name: string;
};

export class UserApi extends Model<
    UserAttributes,
    UserAttributes,
    { ambassador: AmbassadorApi }
> {
    public $resource(): string {
        return "users";
    }
}
