import { createRouter } from "@ionic/vue-router";
import { createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store/index";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        component: () => import("../views/Today.vue"),
        name: "Home",
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/LoginPage.vue"),
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        component: () => import("../views/ChangePassword.vue"),
    },
    {
        path: "/slides",
        name: "Slides",
        component: () => import("../views/WelcomeSlide.vue"),
    },
    {
        path: "/dashboard",
        component: () => import("../views/Dashboard.vue"),
    },
    {
        path: "/current/:tab",
        component: () => import("../views/CurrentShift.vue"),
        name: "MainPage",
    },
    {
        path: "/current/sale/add/note",
        component: () => import("../components/sales/AddNote.vue"),
    },
    {
        path: "/edit/sale/:id/note",
        component: () => import("../components/sales/AddNote.vue"),
    },
    {
        path: "/current/sales/product/find/:categoryId/:brandId",
        component: () => import("../components/product/SelectProduct.vue"),
    },
    {
        path: "/current/sales/product/find/:categoryId",
        component: () => import("../components/product/FilterByBrand.vue"),
    },
    {
        path: "/current/sales/product/find",
        component: () => import("../components/product/FilterByCategory.vue"),
    },
    {
        path: "/sale/detail/:id",
        component: () => import("../components/sales/SaleDetail.vue"),
    },
    {
        path: "/sale/edit/:id",
        component: () => import("../components/sales/SaleEditor.vue"),
        props: true,
    },
    {
        path: "/shift/detail/:id",
        component: () => import("../components/shift/ShiftDetail.vue"),
    },
    {
        path: "/current/shift/stop",
        component: () => import("../components/shift/StopShift.vue"),
    },
    {
        path: "/:catchAll(.*)",
        name: "ErrorPage",
        component: () => import("../components/utils/ErrorPage.vue"),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters.userIsLogged;
    const hasCurrentShift = store.getters.shiftIsEditable;
    // if user is not authenticated, redirect to the Login Page
    if (to.name !== "Login" && !isAuthenticated) next({ name: "Login" });
    // if user has a current shift, shipt start shift page
    if (to.name === "Home" && hasCurrentShift)
        next({ name: "MainPage", params: { tab: "sales" } });
    // if user has not started the shift (or the shift has already finished)
    // and tries to go to the add sales pages, we should avoid this
    if (to.name === "MainPage" && !hasCurrentShift) next({ name: "Home" });
    else next();
});

export default router;
