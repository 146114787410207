
type Page = {
  title: string;
  url?: string;
  action: (index: number) => void;
  iosIcon: typeof calendarClearOutline | typeof analyticsOutline;
  mdIcon: typeof calendarClearOutline | typeof analyticsOutline;
  class: string;
};
import {
    IonAvatar,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    menuController,
    IonItemDivider,
    IonItemGroup, IonItemSliding, IonText
} from "@ionic/vue";
import { computed, defineComponent, onMounted, reactive, ref, toRefs, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  helpCircleOutline,
  informationCircleOutline,
  bookmarkOutline,
  bookmarkSharp,
  logOutOutline,
  keyOutline,
  analyticsOutline,
  calendarClearOutline,
  languageOutline,
  chevronForwardOutline
} from "ionicons/icons";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import LocaleModal from "@/components/locale/LocaleModal.vue";
import { DEFAULT_LANG, SUPPORTED_LANGS, SUPPORTED_LANG_EXTENDED } from "@/utils/Locale";

export default defineComponent({
  components: {
    IonAvatar,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    LocaleModal,
    IonItemDivider,
    IonItemGroup,
  },
  setup() {
    const localeModalRef = ref(null)
    const { t, locale } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const selectedIndex = ref(0);
    const state = reactive({
      appPages: [] as Page[],
    });

    const currentLocaleImgSrc = computed(() => {
      const currentLocale = SUPPORTED_LANGS.find((lang) => lang.value === locale.value);
      if (!currentLocale) return DEFAULT_LANG.imgPath
      return currentLocale.imgPath
    })

    const goHome = (index: number) => {
      menuController.enable(false, "sale-order");
      selectedIndex.value = index;
      router.push("/");
    };

    const goDashboard = (index: number) => {
      menuController.enable(false, "sale-order");
      selectedIndex.value = index;
      router.push("/dashboard");
    };

    const setUpAppPages = () => {
      state.appPages = [
        {
          title: t('general.today'),
          action: goHome,
          iosIcon: calendarClearOutline,
          mdIcon: calendarClearOutline,
          class: store.getters.shiftIsCurrent ? "active-shift" : "",
        },
        {
          title: t('general.dashboard'),
          action: goDashboard,
          iosIcon: analyticsOutline,
          mdIcon: analyticsOutline,
          class: "",
        },
      ];
    };

    watch(
      () => store.getters.shiftIsCurrent,
      () => {
        const routePath = route.fullPath;
        if (routePath.match("dashboard")) selectedIndex.value = 1;
        else selectedIndex.value = 0;
        setUpAppPages();
      }
    );

    onMounted(() => {
      const routePath = route.fullPath;
      if (routePath.match("dashboard")) selectedIndex.value = 1;
      else selectedIndex.value = 0;
      setUpAppPages();
    });

    const logOut = () => {
      menuController.enable(false, "main-content");
      store.dispatch("logOut").then(() => router.push("/login"));
    };

    const changePassword = () => {
      menuController.enable(false, "main-content");
      router.push("/reset-password");
    };

    const goToHelp = () => {
      window.open('https://guide.bacardisalestracking.com/#/help', '_blank');
    }    
    
    const goToUserGuide = () => {
      window.open('https://guide.bacardisalestracking.com/#/', '_blank');
    }

    const changeLocale = () => {
      //@ts-ignore
      localeModalRef?.value?.openLocaleModal()
    }

    const labels = [
      { name: t('general.help'), action: goToHelp, icon: helpCircleOutline },
      { name: t('general.user_guide'), action: goToUserGuide, icon: informationCircleOutline },
      { name: t('general.change_password'), action: changePassword, icon: keyOutline },
      { name: `${t('general.change_locale')}`, action: changeLocale, icon: languageOutline, showLocaleIcon: true },
      { name: t('general.log_out'), action: logOut, icon: logOutOutline },
    ];

    return {
      ...toRefs(state),
      localeModalRef,
      selectedIndex,
      labels,
      bookmarkOutline,
      bookmarkSharp,
      goHome,
      isSelected: (url: string) => (url === route.path ? "selected" : ""),
      user: store.getters.currentUser,
      chevronForwardOutline,
      currentLocaleImgSrc
    };
  },
});
