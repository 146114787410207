import * as Sentry from "@sentry/vue";
import { toastController } from "@ionic/vue";

type positionEnum = "top" | "bottom" | "middle";

export const openToast = async (
    message: string,
    duration = 3000,
    position: positionEnum = "top"
) => {
    const toast = await toastController.create({
        message,
        duration,
        position,
        cssClass: `custom-${position}-toast`,
        buttons: [
            {
                icon: "close",
                role: "cancel",
            },
        ],
    });
    return toast.present();
};

export const saleErrorToast = (error: Error, message = "Sorry, there was an error submitting your sale. Please try again later") => {
    openToast(message);
    Sentry.captureException(error);
};

export const saleConfirmationToast = (message = "Your sales have been added") =>
    openToast(message);

export const saleUpdatedConfirmationToast = (message = "Your sale has been updated") =>
    openToast(message);
