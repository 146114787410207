import { BelongsTo } from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo";
import { HasMany } from "@tailflow/laravel-orion/lib/drivers/default/relations/hasMany";
import { Model } from "@tailflow/laravel-orion/lib/model";
import { ShiftApi } from "./ShiftApi";
import { UserApi } from "./UserApi";

type UserAttributes = {
    id: number;
    name: string;
};

export class AmbassadorApi extends Model<
    UserAttributes,
    UserAttributes,
    { shifts: ShiftApi; user: UserApi }
> {
    public $resource(): string {
        return "ambassadors";
    }
    public shifts(): HasMany<ShiftApi> {
        return new HasMany(ShiftApi, this);
    }
    public user(): BelongsTo<UserApi> {
        return new BelongsTo(UserApi, this);
    }
}
