import { AlertButton, alertController, IonicSafeString } from "@ionic/vue";

export const presentAlert = async (message: string | IonicSafeString, buttons: AlertButton[], header = 'Before you submit...!', backdropDismiss = false) => {
    const alert = await alertController.create({
        cssClass: "submit-basket-modal",
        header,
        message,
        buttons,
        backdropDismiss: backdropDismiss,
    });
    return alert.present();
};
