import general from './general.json'
import login from './login.json'
import today from './today.json'
import welcome from './welcome.json'
import basket from './basket.json'
import product from './product.json'
import sales from './sales.json'
import shift from './shift.json'

export default { general, login, today, welcome, basket, product, sales, shift }
