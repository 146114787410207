import { AmbassadorApi } from "./models/AmbassadorApi";
import { presentAlert } from "@/utils/Alert";
import { i18n } from "@/main";

export const getAmbassador = (ambassadorId: number) => {
    return AmbassadorApi.$query()
        .find(ambassadorId)
        .then((user) => {
            return user;
        })
        .catch((error) => {
            console.error(error);
            presentAlert(error.message.capitalize(), [{
                text:  i18n.global.t('general.retry'),
                role: "confirm",
                handler: () => window.location.reload(),
            }], i18n.global.t('general.connection_error'), true)
            throw error;
        });
};
