
import { setUserLocaleSetting, SUPPORTED_LANGS, SUPPORTED_LANG } from "@/utils/Locale";
import { IonPage, IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonItem, IonLabel } from "@ionic/vue";
import { defineComponent, reactive, toRefs } from "vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
  components: {
    IonPage,
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonItem,
    IonLabel
  },
  setup(props, context) {
    const { locale } = useI18n()
    const state = reactive({
      modal: false,
      langs: [...SUPPORTED_LANGS]
    });

    const openLocaleModal = () => state.modal = true
    const closeLocaleModal = () => state.modal = false

    context.expose({
      openLocaleModal,
      closeLocaleModal
    })

    const setUserLangSetting = (lang: SUPPORTED_LANG) => {
      console.log('setUserLocaleSetting', lang)
      setUserLocaleSetting(lang)
      locale.value = lang;
      closeLocaleModal();
      //TODO: Try to avoid using this reload function
      window.location.reload();
    }

    return {
      openLocaleModal,
      closeLocaleModal,
      setUserLangSetting,
      ...toRefs(state),
    }

  },
});
