import { Order } from "@/classes/Order";
import { ProductLine } from "@/classes/ProductLine";
import { formatOrder } from "./mappers/OrderMapper";
import { SaleApi } from "./models/SaleApi";
import axios from "axios";
import { API_URL } from "@/utils/constants";
import { getToken } from "@/utils/AuthHelper";
import { ShiftApi } from "./models/ShiftApi";

const getShiftByID = (shiftId: number) => ShiftApi.$query().find(shiftId);

const getSaleApiById = (id:number) => {
    return SaleApi.$query()
        .with(["products.category"])
        .find(id)
}

export const updateOrder = (order: Order, orderId: number) =>
    attachProductsToOrder(order.productLines, orderId);

export const publishOrder = (order: Order, shiftId: number): Promise<any> => {
    return getShiftByID(shiftId).then((shift) =>
        shift
            .sales()
            //@ts-ignore
            .store({
                shift_id: shiftId,
                notes: order.note,
            })
            .then((_sale: SaleApi) => {
                return attachProductsToOrder(
                    order.productLines,
                    _sale.$attributes.id
                );
            })
    );
};

export const getOrderById = (id: number): Promise<Order> => {
    return getSaleApiById(id)
        .then((order) => {
            return formatOrder(order);
        });
};

export const attachProductsToOrder = (
    productLines: ProductLine[],
    saleId: number
): Promise<number> => {
    const payload = Object.values(productLines).reduce(
        (previous, productLine) => {
            return {
                ...previous,
                [productLine.product.id]: {
                    quantity: productLine.quantity,
                },
            };
        },
        {}
    );
    const token = getToken();
    const options = {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
    };

    return axios
        .post(
            `${API_URL}/api/sales/${saleId}/products/attach`,
            { resources: { ...payload } },
            options
        )
        .then(() => saleId);
};

export const updateSaleNotes = (saleId: number, note: string) => {
    return getSaleApiById(saleId).then((order: SaleApi) => {
        order.$attributes.notes = note;
        return order.$save();
    });
};