import axios from "axios";
import { API_URL, AUTH_TOKEN_NAME } from "@/utils/constants";
import { Orion } from "@tailflow/laravel-orion/lib/orion";

export type LoginParams = {
    email: string;
    password: string;
};

export type AmbassadorData = {
    id: number;
    name: string;
};

export type UserData = {
    id: number;
    email: string;
    name: string;
    avatar?: string;
    ambassador: AmbassadorData;
};

export const login = ({ email, password }: LoginParams): Promise<UserData> => {
    const options = {
        withCredentials: false,
    };
    return axios
        .post(
            `${API_URL}/api/auth/token-exchange`,
            { email, password },
            options
        )
        .then((response) => {
            const {
                data: { token, user },
            } = response;
            localStorage.setItem(AUTH_TOKEN_NAME, token);
            Orion.setToken(token);
            return {
                ...user,
            } as UserData;
        });
};
