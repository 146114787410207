import { createStore } from "vuex";
import { saleModule } from "@/store/modules/sale";
import { authModule } from "@/store/modules/auth";
import { shiftModule } from "@/store/modules/shift";
import createPersistedState from "vuex-persistedstate";

const appState = createPersistedState({
    paths: ["sale", "auth", "shift"],
});

export default createStore({
    modules: {
        sale: saleModule,
        auth: authModule,
        shift: shiftModule,
    },
    plugins: [appState],
});
