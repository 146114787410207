export const customInterval = (
    resolve: (intervalId: number) => void,
    reject: () => void
) => {
    const intervalId = setInterval(() => resolve(intervalId), 1000);
    addEventListener("visibilitychange", () => {
        if (document.visibilityState === "hidden") {
            reject();
        }
        if (document.visibilityState === "visible") {
            const intervalId = setInterval(() => resolve(intervalId), 1000);
        }
    });
};
