
import SaleOrderHeader from "@/components/sales/SaleOrderHeader.vue";
import {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonTitle,
    menuController,
} from "@ionic/vue";
import { useStore } from "vuex";
import { watch, defineComponent, reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
    name: "header-section",
    components: {
        SaleOrderHeader,
        IonHeader,
        IonToolbar,
        IonButtons,
        IonMenuButton,
        IonTitle,
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const state = reactive({
            showMenuBtn: false,
            showBasket: false,
        });
        onMounted(() => {
            state.showMenuBtn = store.getters.userIsLogged;
            state.showBasket =
                store.getters.userIsLogged && store.getters.shiftIsEditable;
        });
        watch(
            () => (store.getters.userIsLogged, store.getters.shiftIsEditable),
            () => {
                state.showMenuBtn = store.getters.userIsLogged;
                state.showBasket =
                    store.getters.userIsLogged && store.getters.shiftIsEditable;
            }
        );

        return {
            ...toRefs(state),
            goHome: () => router.push("/"),
            handleMenuClick: () => menuController.enable(true, "main-content"),
        };
    },
});
