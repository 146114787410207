import { publishOrder } from "@/api/order";
import { Order } from "@/classes/Order";
import { Product } from "@/classes/Product";
import { ProductLine } from "@/classes/ProductLine";
import { Module } from "vuex";

export const saleModule: Module<any, any> = {
    state: () => ({
        sale: {
            productLines: {},
            note: "",
            id: "",
            time: "",
        },
    }),
    getters: {
        currentSaleNote(state) {
            return state.sale.note;
        },
        amountPresentInTheBasket(state) {
            return function (productId: number) {
                if (
                    productId &&
                    state.sale.productLines &&
                    state.sale.productLines[productId]
                )
                    return state.sale.productLines[productId]?.quantity ?? 0;
                return 0;
            };
        },
        getTotalAmountOfProductsInOrder(state): number {
            const productListCopy: ProductLine = state.sale.productLines;
            if (!productListCopy) return 0;
            return Object.entries(productListCopy).reduce(
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                (previous: number, [key, productLine]) => {
                    return previous + productLine.quantity;
                },
                0
            );
        },
        order(state): Order {
            return {
                ...state.sale,
            };
        },
    },
    mutations: {
        resetSale(state) {
            state.sale = {
                productLines: {},
                note: "",
                id: "",
                time: "",
            };
        },
        setNote(state, note) {
            state.sale.note = note;
        },
        initNewSale(state) {
            state.sale.productLines = {};
            state.sale.note = "";
        },
        addProductToSale(state, { product, quantity = 1 }: ProductLine) {
            let previousAmount;
            const productListCopy = state.sale.productLines;
            if (
                productListCopy[product.id] &&
                productListCopy[product.id]?.quantity
            )
                previousAmount = productListCopy[product.id]?.quantity;
            else previousAmount = 0;
            if (previousAmount + quantity === 0)
                delete productListCopy[product.id];
            else {
                productListCopy[product.id] = {
                    product,
                    quantity: previousAmount + quantity,
                };
            }
            state.sale.products = {
                ...productListCopy,
            };
        },
    },
    actions: {
        resetCurrentSale({ commit }) {
            commit("resetSale");
        },
        updateNote({ commit }, note) {
            commit("setNote", note);
        },
        submitSale(
            { commit },
            { order, shiftId }: { order: Order; shiftId: number }
        ) {
            return publishOrder(order, shiftId).then((orderId: number) => {
                commit("initNewSale");
                return orderId;
            });
        },
        addProduct({ commit }, product: Product): void {
            commit("addProductToSale", { product });
        },
        addProductLine({ commit }, { product, quantity }: ProductLine): void {
            commit("addProductToSale", { product, quantity });
        },
    },
};
