import { loadingController } from "@ionic/vue";

export const presentLoading = async () => {
    const loading = await loadingController.create({
        cssClass: "custom-loader",
        mode: "ios",
        spinner: "circles",
    });

    await loading.present();

    return loading;
};
