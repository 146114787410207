import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasketSidebar = _resolveComponent("BasketSidebar")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_openBlock(), _createBlock(_component_ion_menu, {
    "content-id": "main",
    type: "overlay",
    side: "end",
    "menu-id": "sale-order"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BasketSidebar)
    ]),
    _: 1
  }))
}