
import { defineComponent, reactive, toRefs, onErrorCaptured } from "vue";
import ErrorPage from "@/components/utils/ErrorPage.vue";
import * as Sentry from "@sentry/vue";

export default defineComponent({
  components: { ErrorPage },
  setup() {
    const state = reactive({
      err: undefined as Error | undefined,
      vm: {} as any,
      info: "",
    });
    onErrorCaptured((err, vm, info) => {
      Sentry.captureException(err)
      state.err = err;
      state.vm = vm;
      state.info = info;
      return false;
    });
    return {
      ...toRefs(state),
    };
  },
});
