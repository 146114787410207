import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4725e3b5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonTitle = _resolveComponent("IonTitle")!
  const _component_IonButton = _resolveComponent("IonButton")!
  const _component_IonButtons = _resolveComponent("IonButtons")!
  const _component_IonToolbar = _resolveComponent("IonToolbar")!
  const _component_IonHeader = _resolveComponent("IonHeader")!
  const _component_IonLabel = _resolveComponent("IonLabel")!
  const _component_IonItem = _resolveComponent("IonItem")!
  const _component_IonContent = _resolveComponent("IonContent")!
  const _component_IonPage = _resolveComponent("IonPage")!
  const _component_IonModal = _resolveComponent("IonModal")!

  return (_openBlock(), _createBlock(_component_IonModal, {
    "is-open": _ctx.modal,
    onDidDismiss: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modal = false)),
    "swipe-to-close": true,
    mode: "ios"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_IonPage, null, {
        default: _withCtx(() => [
          _createVNode(_component_IonHeader, { translucent: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_IonToolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_IonTitle, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('general.change_locale')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_IonButtons, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_IonButton, {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modal = false))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('product.close')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_IonContent, {
            translucent: "",
            class: "ion-padding"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.langs, (lang, index) => {
                return (_openBlock(), _createBlock(_component_IonItem, {
                  class: "menu-action lang-box",
                  lines: "none",
                  key: index,
                  onClick: ($event: any) => (_ctx.setUserLangSetting(lang.value)),
                  color: "white"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: lang.imgPath,
                      class: "flag-icon"
                    }, null, 8, _hoisted_1),
                    _createVNode(_component_IonLabel, { class: "lang-label" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(lang.name), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-open"]))
}