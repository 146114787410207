
import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/vue";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watch,
} from "vue";
import LeftSidebar from "./layout/LeftSidebar.vue";
import MainAppHeaderSection from "@/layout/MainAppHeader.vue";
import ErrorHandler from "@/components/utils/ErrorHandler.vue";
import RightSidebar from "./layout/RightSidebar.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { customInterval } from "./utils/CustomFunctions";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    MainAppHeaderSection,
    IonSplitPane,
    LeftSidebar,
    RightSidebar,
    ErrorHandler,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const state = reactive({
      showMenuBtn: false,
      showBasket: false,
      intervalId: 0,
    });

    onMounted(() => {
      state.showMenuBtn = store.getters.userIsLogged;
      state.showBasket =
        store.getters.userIsLogged && store.getters.shiftIsEditable;
    });

    const intervalToCheckStatus = () => {
      if (!store.getters.shiftIsCurrent) {
        clearInterval(state.intervalId);
      }
    };

    onMounted(() => {
      if (store.getters.shiftIsCurrent) {
        // Interval will run until shift is not longer a current shift
        intervalToCheckStatus();
        customInterval(
          (shiftId) => {
            state.intervalId = shiftId;
            intervalToCheckStatus();
          },
          () => clearInterval(state.intervalId)
        );
      }
    });

    watch(
      () => (store.getters.userIsLogged, store.getters.shiftIsEditable),
      () => {
        state.showMenuBtn = store.getters.userIsLogged;
        state.showBasket =
          store.getters.userIsLogged && store.getters.shiftIsEditable;
      }
    );
    const showMainHeader = computed(() => {
      const pagesWithoutHeader = ["Login", "Slides"];
      const currentRouteName = route.name as string;
      return !pagesWithoutHeader.includes(currentRouteName);
    });

    return {
      ...toRefs(state),
      showMainHeader,
    };
  },
});
