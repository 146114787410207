import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainAppHeaderSection = _resolveComponent("MainAppHeaderSection")!
  const _component_LeftSidebar = _resolveComponent("LeftSidebar")!
  const _component_RightSidebar = _resolveComponent("RightSidebar")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_split_pane = _resolveComponent("ion-split-pane")!
  const _component_ErrorHandler = _resolveComponent("ErrorHandler")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_ErrorHandler, null, {
        default: _withCtx(() => [
          (_ctx.showMainHeader)
            ? (_openBlock(), _createBlock(_component_MainAppHeaderSection, { key: 0 }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_split_pane, { "content-id": "main" }, {
            default: _withCtx(() => [
              (_ctx.showMenuBtn && _ctx.showMainHeader)
                ? (_openBlock(), _createBlock(_component_LeftSidebar, { key: 0 }))
                : _createCommentVNode("", true),
              (_ctx.showBasket && _ctx.showMainHeader)
                ? (_openBlock(), _createBlock(_component_RightSidebar, { key: 1 }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_router_outlet, { id: "main" })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}