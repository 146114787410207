
import { Product } from "@/classes/Product";
import { ProductLine } from "@/classes/ProductLine";
import { defineComponent, PropType } from "vue";
import AddIcon from "vue-ionicons/dist/ios-add.vue";
import RemoveIcon from "vue-ionicons/dist/ios-remove.vue";
import { IonRow, IonCol } from "@ionic/vue";

export default defineComponent({
  components: {
    AddIcon,
    RemoveIcon,
    IonRow,
    IonCol,
  },
  props: {
    productLines: {
      type: [] as PropType<ProductLine[]>,
    },
    listType: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    return {
      productRemoved: (product: Product) =>
        context.emit("productRemoved", product),
      productAdded: (product: Product) => context.emit("productAdded", product),
    };
  },
});
