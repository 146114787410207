import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5096e334"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-list-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonRow = _resolveComponent("IonRow")!
  const _component_IonCol = _resolveComponent("IonCol")!
  const _component_RemoveIcon = _resolveComponent("RemoveIcon")!
  const _component_AddIcon = _resolveComponent("AddIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productLines, (productLine) => {
      return (_openBlock(), _createBlock(_component_IonRow, {
        key: productLine.product.id,
        class: _normalizeClass(["product-list", _ctx.listType])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IonCol, {
            class: "nm product-column",
            size: "8"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IonRow, { class: "np nm product-name" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(productLine.product.name), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_IonRow, { class: "np nm subtitles" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(productLine.product.category), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_IonCol, {
            class: "nm",
            size: "4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IonRow, { class: "np nm" }, {
                default: _withCtx(() => [
                  _createVNode(_component_IonCol, { class: "np nm basket-aggregation-btn" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_RemoveIcon, {
                        class: "pointer",
                        onClick: ($event: any) => (_ctx.productRemoved(productLine.product))
                      }, null, 8, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_IonCol, { class: "np nm basket-quantity-holder" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(productLine.quantity), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_IonCol, { class: "np nm basket-aggregation-btn" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AddIcon, {
                        class: "pointer",
                        onClick: ($event: any) => (_ctx.productAdded(productLine.product))
                      }, null, 8, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1032, ["class"]))
    }), 128))
  ]))
}