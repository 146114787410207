import { Model } from "@tailflow/laravel-orion/lib/model";
import { BelongsTo } from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo";
import { CategoryApi } from "./CategoryApi";
import { BrandLineApi } from "./BrandLineApi";

export type ProductApiAttributes = {
    sku: string;
    id: number;
};
export class ProductApi extends Model<
    ProductApiAttributes,
    ProductApiAttributes,
    {
        brand_line: BrandLineApi;
        category: CategoryApi;
    }
> {
    public $resource(): string {
        return "products";
    }
    public category(): BelongsTo<CategoryApi> {
        return new BelongsTo(CategoryApi, this);
    }
    public brand_line(): BelongsTo<BrandLineApi> {
        return new BelongsTo(BrandLineApi, this);
    }
}
