import { login, LoginParams, UserData } from "@/api/auth";
import { AUTH_TOKEN_NAME } from "@/utils/constants";
import { Module } from "vuex";

export const authModule: Module<any, any> = {
    state: () => ({
        auth: {
            user: {
                id: '',
                ambassador: {
                    id: ''
                }
            },
        },
    }),
    getters: {
        userIsLogged(state) {
            return state.auth.user.id && localStorage.getItem(AUTH_TOKEN_NAME);
        },
        currentUser(state) {
            return { ...state.auth.user };
        },
        currentUserId(state) {
            return state.auth.user.id;
        },
        currentAmbassadorId(state) {
            return state.auth.user.ambassador.id;
        },
    },
    mutations: {
        storeUserData(state, userData: UserData) {
            state.auth.user = { ...userData };
        },
        setUserAsConnected(state) {
            state.auth.isLogged = true;
        },
        setUserAsDisconnected(state) {
            state.auth.isLogged = false;
        },
    },
    actions: {
        authenticate({ commit }, { email, password }: LoginParams) {
            return login({ email, password }).then((userData) => {
                commit("storeUserData", userData);
                return commit("setUserAsConnected");
            });
        },
        logOut({ commit }) {
            commit("storeUserData", {
                email: "",
                name: "",
                password: "",
                id: "",
                ambassador: {
                    id: "",
                },
            });
            return commit("setUserAsDisconnected");
        },
    },
};
