
import { IonRow, IonButton, menuController, IonContent } from "@ionic/vue";
import { defineComponent, computed, ComputedRef } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Product } from "@/classes/Product";
import { presentLoading } from "@/utils/Loader";
import { saleConfirmationToast, saleErrorToast } from "@/utils/ToastController";
import { Order } from "@/classes/Order";
import ProductLineList from "@/components/basket/ProductLineList.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    IonRow,
    IonButton,
    IonContent,
    ProductLineList,
  },
  setup() {
    const { t } = useI18n()
    const store = useStore();
    const router = useRouter();

    const order: ComputedRef<Order> = computed(() => store.getters.order);
    const basketProductLines = computed(() => store.getters.order.productLines);

    const productsInTheBasket = computed(
      () => store.getters.getTotalAmountOfProductsInOrder
    );

    const goHome = () => {
      menuController.enable(false, "sale-order");
      router.push(`/`);
    };

    const addNote = () => {
      menuController.enable(false, "sale-order");
      router.push(`/current/sale/add/note`);
    };

    const submitSaleHandler = async () => {
      const loader = await presentLoading();
      return store
        .dispatch("submitSale", {
          order: store.getters.order,
          shiftId: store.getters.shiftId,
        })
        .then(goHome)
        .then(() => saleConfirmationToast(t('basket.submit_confirmation')))
        .catch((error) => saleErrorToast(error, t('basket.submit_error')))
        .finally(() => loader.dismiss());
    };

    const removeProductFromSale = (product: Product) => {
      store.dispatch("addProductLine", { product, quantity: -1 });
    };

    const addProductFromSale = (product: Product) => {
      store.dispatch("addProduct", product);
    };

    return {
      order,
      productsInTheBasket,
      basketProductLines,
      submitSaleHandler,
      addNote,
      addProductFromSale,
      removeProductFromSale,
    };
  },
});
