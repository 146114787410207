import { FINISHED_SHIFT_STATUS } from "@/api/shift";
import { Shift } from "@/classes/Shift";
import {
    addHoursToDate,
    currentDateOnRange,
    dateFromThePast,
    dateInTheFuture,
    hasPassed24Hours,
} from "./DateManager";

export const THRESHOLD_TO_EDIT_IN_HS = 12;

export const shiftIsOnActiveMode = (shift: Shift) => {
    // if (isFutureShift(shift) || isPastShift(shift)) return true;
    return currentDateOnRange({
        startDate: shift.startTime,
        endDate: shift.endTime,
    });
};

export const shiftIsOnEditMode = (shift: Shift) => {
    console.log("shiftIsOnEditMode", shift.status);
    console.log("shiftIsOnEditMode", isFutureShift(shift), isPastShift(shift));
    if (isFutureShift(shift) || isPastShift(shift)) return true;
    const isCurrent = currentDateOnRange({
        startDate: shift.startTime,
        endDate: addHoursToDate(shift.endTime, THRESHOLD_TO_EDIT_IN_HS),
    });
    return isCurrent && shift.status !== FINISHED_SHIFT_STATUS;
};

export const isFutureShift = (shift: Shift) => {
    return (
        shift.status &&
        shift.status !== FINISHED_SHIFT_STATUS &&
        dateInTheFuture(shift.startTime)
    );
};
export const isPastShift = (shift: Shift) => {
    return (
        shift.status &&
        shift.status !== FINISHED_SHIFT_STATUS &&
        dateFromThePast(shift.endTime) &&
        !hasPassed24Hours(shift.endTime)
    );
};

export const isCurrentShift = (shift: Shift) => {
    return !isFutureShift(shift) && !isPastShift(shift);
};

export const shiftHasBeenPublished = (shift: Shift) =>
    shift.status === FINISHED_SHIFT_STATUS;

export const shiftIsOnClosed = (shift: Shift) => !shiftIsOnEditMode(shift);
