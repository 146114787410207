import { Product } from "@/classes/Product";
import { ProductLine } from "@/classes/ProductLine";
import { ProductApi } from "../models/ProductApi";
import { ProductLineApi } from "../models/SaleApi";

export const productMapper = (product: ProductApi): Product => {
    return {
        id: product.$attributes.id,
        name: product.$attributes.sku,
        category: product.$relations.category?.$attributes.name,
        // brand: product.$relations.brand_line.$attributes.name,
    };
};

export const productLineApiMapper = (product: ProductLineApi): Product => {
    return {
        id: product.$attributes.id,
        name: product.$attributes.sku,
        category: product.$relations?.category?.$attributes?.name,
    };
};

export const productsMapper = (products: ProductApi[]): Product[] =>
    products.map(productMapper);

export const productLineMapper = (product: ProductLineApi): ProductLine => ({
    product: productLineApiMapper(product),
    // TODO Fix this issue with ts
    //@ts-ignore
    quantity: product.$attributes.pivot.quantity,
});

export const productsLineMapper = (
    products: ProductLineApi[]
): ProductLine[] => {
    return products.map(productLineMapper);
};