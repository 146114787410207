import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Orion } from "@tailflow/laravel-orion/lib/orion";
import axios, {
    AxiosInstance,
} from "@tailflow/laravel-orion/node_modules/axios/index";
import { IonicVue } from "@ionic/vue";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { createI18n } from "vue-i18n";
import messages from "./../i18n";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/variables.scss";
import "./theme/styles.scss";

import "vue-ionicons/ionicons.css";
import {
    API_URL,
    AUTH_TOKEN_NAME,
    UNAUTHORIZED_ACTION_STATUS,
} from "./utils/constants";
import { getUserLocale } from "./utils/Locale";

Orion.init(`${API_URL}/`);

Object.defineProperty(String.prototype, "capitalize", {
    value: function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false,
});

Orion.makeHttpClientUsing((): AxiosInstance => {
    const token = localStorage.getItem(AUTH_TOKEN_NAME);
    const client = axios.create({
        baseURL: `${API_URL}/api/`,
        timeout: 15000,
        headers: { Authorization: `Bearer ${token}` },
    });
    client.interceptors.request.use((config) => {
        config.headers = { Authorization: `Bearer ${token}` };
        return config;
    });
    client.interceptors.response.use(
        (config) => config,
        (error) => {
            if (
                error.response &&
                error.response.status === UNAUTHORIZED_ACTION_STATUS
            ) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                router.push({ name: "Login" });
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.error(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
            }
            //do redirect to login if token has expired
            return Promise.reject(error);
        }
    );
    return client;
});

const locale = getUserLocale();
console.log("getUserLocale", locale);
export const i18n = createI18n({
    legacy: false,
    locale, // set locale
    fallbackLocale: "en", // set fallback locale
    messages, // set locale messages
});

const app = createApp(App)
    .use(IonicVue, {
        mode: "md",
    })
    .use(router)
    .use(i18n)
    .use(store);

Sentry.init({
    app,
    dsn: "https://9fa4f12652e74c8481487b900f845d7b@o194331.ingest.sentry.io/6624557",
    integrations: [],
    enabled: process.env.NODE_ENV === "production",
    logErrors: true,
});

router.isReady().then(() => {
    app.mount("#app");
});
