import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19588fe8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "basket-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonRow = _resolveComponent("IonRow")!
  const _component_ProductLineList = _resolveComponent("ProductLineList")!
  const _component_IonButton = _resolveComponent("IonButton")!
  const _component_IonContent = _resolveComponent("IonContent")!

  return (_openBlock(), _createBlock(_component_IonContent, null, {
    default: _withCtx(() => [
      _createVNode(_component_IonRow, null, {
        default: _withCtx(() => [
          _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('sales.your_basket')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ProductLineList, {
        listType: "condense",
        productLines: _ctx.basketProductLines,
        onProductRemoved: _ctx.removeProductFromSale,
        onProductAdded: _ctx.addProductFromSale
      }, null, 8, ["productLines", "onProductRemoved", "onProductAdded"]),
      _createVNode(_component_IonRow, null, {
        default: _withCtx(() => [
          _createVNode(_component_IonButton, {
            expand: "full",
            class: "add-sale-btn submit",
            disabled: _ctx.productsInTheBasket === 0,
            onClick: _ctx.submitSaleHandler
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('sales.add_sales')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}