import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonIcon = _resolveComponent("IonIcon")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!

  return (_openBlock(), _createBlock(_component_ion_menu_button, {
    "auto-hide": "false",
    menu: "sale-order",
    class: "sale-order-menu-btn",
    onClick: _ctx.handleMenuClick
  }, {
    default: _withCtx(() => [
      _createVNode(_component_IonIcon, {
        class: "basket-icon",
        md: _ctx.basketOutline
      }, null, 8, ["md"]),
      _createVNode(_component_ion_badge, {
        color: "primary",
        id: "cart-count-badge"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.productsOnCurrentSale), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClick"]))
}