import { DateTime, Duration, DurationLike, DurationLikeObject } from "luxon";
import { getUserLocale } from "./Locale";
const locale = getUserLocale();
export interface DateRange {
    startDate: string;
    endDate: string;
}

export const initializeDateTime = (date: string) =>
    DateTime.fromISO(date).setLocale(locale || "en");

export const dateAtTimeZero = (date: string) => {
    const now = DateTime.fromISO(date).setLocale(locale || "en");
    return now.startOf("day");
};

export const formattedDate = (date: string) => {
    return initializeDateTime(date).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
    );
};

export const getDayFromDate = (date: string) => {
    return initializeDateTime(date).toFormat("EEE");
};

export const shortFormattedDate = (date: string) => {
    return initializeDateTime(date).toLocaleString({
        day: "2-digit",
        month: "short",
    });
};

export const extractDay = (date: string) => {
    return initializeDateTime(date).toLocaleString({
        day: "2-digit",
    });
};

export const formattedTime = (date: string) => {
    return initializeDateTime(date).toLocaleString(DateTime.TIME_24_SIMPLE);
};

export const generateDiffDateFromStart = (date: string, duration: Duration) => {
    const initOfDay = initializeDateTime(date).startOf("day").toISO();
    return aggregateTimeToDate(initOfDay, duration);
};

export const calcShiftLength = (startDate: string, endDate: string) =>
    initializeDateTime(endDate).diff(initializeDateTime(startDate));

export const calcDiff = (
    startDate: string,
    endDate: string,
    unit: keyof DurationLikeObject = "hours"
) => {
    return initializeDateTime(endDate)
        .diff(initializeDateTime(startDate))
        .as(unit)
        .toPrecision(2);
};

export const dateFromThePast = (dateString: string) => {
    const date = initializeDateTime(dateString);
    const now = DateTime.local();
    now.setZone(date.zoneName);
    return date < now;
};

export const dateInTheFuture = (dateString: string) => {
    return !dateFromThePast(dateString);
};

const getRangeFromDate = (date: DateTime): DateRange => {
    return {
        startDate: date
            .setLocale("UTC")
            .startOf("week")
            .setLocale(locale || "en")
            .toISO(),
        endDate: date
            .setLocale("UTC")
            .endOf("week")
            .setLocale(locale || "en")
            .toISO(),
    };
};

export const getCurrentWeekRange = (): Promise<DateRange> => {
    return Promise.resolve(getRangeFromDate(DateTime.now()));
};

export const getNextWeekRange = (dateRange: DateRange): DateRange => {
    const { startDate: fromDate } = dateRange;
    return getRangeFromDate(initializeDateTime(fromDate).plus({ week: 1 }));
};

export const getPreviousWeek = (date: string) =>
    initializeDateTime(date).minus({ week: 1 });

export const getPreviousWeekRange = (dateRange: DateRange): DateRange => {
    const { startDate: fromDate } = dateRange;
    return getRangeFromDate(getPreviousWeek(fromDate));
};

export const currentDateOnRange = (dateRange: DateRange): boolean => {
    const { startDate, endDate } = dateRange;
    const currentDate = DateTime.now();
    return (
        currentDate >= initializeDateTime(startDate) &&
        initializeDateTime(endDate) >= currentDate
    );
};

export const addHoursToDate = (date: string, hours: number) => {
    return initializeDateTime(date).plus({ hours: hours }).toISO();
};

export const add1DayToDate = (date: string) => {
    return initializeDateTime(date).plus({ day: 1 }).toISO();
};

export const reduce1DayToDate = (date: string) => {
    return initializeDateTime(date).minus({ day: 1 }).toISO();
};

export const add15MinutesToDate = (date: string) => {
    return initializeDateTime(date).plus({ minutes: 15 }).toISO();
};

export const reduce15MinutesToDate = (date: string) => {
    return initializeDateTime(date).minus({ minutes: 15 }).toISO();
};

export const aggregateTimeToDate = (date: string, duration: DurationLike) => {
    return initializeDateTime(date).plus(duration).toISO();
};

export const getShiftRangeFromCurrentDate = () => {
    const date = DateTime.now();
    const DEFAULT_SHIFT_LENGTH = 4;
    return {
        startDate: date.toISO(),
        endDate: date
            .plus({ hours: DEFAULT_SHIFT_LENGTH })
            .setLocale(locale || "en")
            .toISO(),
        duration: date
            .startOf("day")
            .plus({ hours: DEFAULT_SHIFT_LENGTH })
            .setLocale(locale || "en")
            .toISO(),
    };
};

export const getCurrentDate = () => {
    const date = DateTime.now().setLocale(locale || "en");
    return date.toISO();
};

export const getYesterdayDate = () => {
    const date = DateTime.now().setLocale(locale || "en");
    return date.minus({ day: 1 }).toISO();
};

export const currentDateForDB = () => changeTimeToDbFormat(getCurrentDate());
export const yesterdayDateForDB = () =>
    changeTimeToDbFormat(getYesterdayDate());

export const getHourWithMinutes = (
    date: string
): Partial<DurationLikeObject> => {
    const dateTime = initializeDateTime(date);
    return {
        hours: dateTime.hour,
        minutes: dateTime.minute,
    };
};

export const datesFromSameDay = (dateOne: string, dateTwo: string) => {
    const dateOneTime = initializeDateTime(dateOne);
    const dateTwoTime = initializeDateTime(dateTwo);
    return dateOneTime.day === dateTwoTime.day;
};

export const getPercentageOfShift = (
    startDate: string,
    endDate: string,
    unit: keyof DurationLikeObject = "hours"
) => {
    const date = DateTime.now();
    const shiftLength = initializeDateTime(endDate)
        .diff(initializeDateTime(startDate))
        .as(unit)
        .toPrecision(8);
    const timeUntilEnd = initializeDateTime(endDate)
        .diff(date)
        .as(unit)
        .toPrecision(8);
    const timePass = parseFloat(shiftLength) - parseFloat(timeUntilEnd);
    return timePass * (100 / parseFloat(shiftLength));
};

export const changeTimeToDbFormat = (date: string) => {
    return DateTime.fromISO(date, { zone: "UTC" }).toISO();
};

export const timeFromStartTime = (startTime: string) => {
    const now = DateTime.now();
    const date = initializeDateTime(startTime);
    const duration = now.diff(date, ["hours", "minutes"]).toObject();
    return now.startOf("day").plus(duration).toFormat("HH:mm:ss");
};

export const getTimeWithSeconds = (date: string) => {
    return initializeDateTime(date).toFormat("HH:mm:ss");
};

export const hasPassed24Hours = (date: string) => {
    const now = DateTime.now();
    const dateToCompare = initializeDateTime(date);
    return now.diff(dateToCompare, "hours").as("hours") >= 24;
};

export const isSameDay = (dateOne: DateTime, dateTwo: DateTime) => {
    return dateOne.hasSame(dateTwo, "day");
};
