
import { IonBadge, IonMenuButton, IonIcon, menuController } from "@ionic/vue";
import { defineComponent, computed } from "vue";
import { useStore } from 'vuex'
import { basketOutline } from "ionicons/icons"
export default defineComponent({
  components: { IonBadge, IonMenuButton, IonIcon },
  setup () {
    const store = useStore();

    const productsOnCurrentSale = computed(() => store.getters.getTotalAmountOfProductsInOrder)

    return {
      productsOnCurrentSale,
      basketOutline,
      handleMenuClick: () => menuController.enable(true, "sale-order")
    }
  }
})
