import { Order } from "@/classes/Order";
import { SaleApi } from "../models/SaleApi";
import { productsLineMapper } from "./ProductMapper";

export const formatOrder = (sale: SaleApi): Order => {
    return {
        uuid: `S${sale.$attributes.id}`,
        id: sale.$attributes.id,
        productLines: productsLineMapper(sale.$relations.products),
        note: sale.$attributes.notes,
        time: sale.$attributes.created_at,
    };
};

export const formatOrders = (sales: SaleApi[]): Order[] => {
    return sales.map(formatOrder);
};
